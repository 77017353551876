<template>
<div id="header">
  <div id="heading">
    <vue-typed-js :strings="['Blog.','Food.', 'Photography.', 'Tech.', 'Stuff.']" :loop="true" :typeSpeed="60" :backSpeed="30" :backDelay="1500">
        <router-link to="/" tag="h1">$ <span class="typing"></span></router-link>
    </vue-typed-js> 
    <div id="heading-right">
      <SearchFocus @keyup="focusSearch"></SearchFocus>
      <VueFuse 
        class="search" 
        :list="posts" 
        :fuse-opts="options"
        @fuse-results="handleResults" 
        :defaultAll="false"
        placeholder="Search ( Press  '/'  to focus )">
      </VueFuse>
      <a href="https://yudishthirlokhande.me"><svg class="svg-icon" viewBox="0 0 20 20">
                <path fill="none" d="M19.629,9.655c-0.021-0.589-0.088-1.165-0.21-1.723h-3.907V7.244h1.378V6.555h-2.756V5.866h2.067V5.177h-0.689V4.488h-1.378V3.799h0.689V3.11h-1.378V2.421h0.689V1.731V1.294C12.88,0.697,11.482,0.353,10,0.353c-5.212,0-9.446,4.135-9.629,9.302H19.629z M6.555,2.421c1.522,0,2.756,1.234,2.756,2.756S8.077,7.933,6.555,7.933S3.799,6.699,3.799,5.177S5.033,2.421,6.555,2.421z"></path>
                <path fill="none" d="M12.067,18.958h-0.689v-0.689h2.067v-0.689h0.689V16.89h2.067v-0.689h0.689v-0.689h-1.378v-0.689h-2.067v-0.689h1.378v-0.689h2.756v-0.689h-1.378v-0.689h3.218c0.122-0.557,0.189-1.134,0.21-1.723H0.371c0.183,5.167,4.418,9.302,9.629,9.302c0.711,0,1.401-0.082,2.067-0.227V18.958z"></path>
              </svg>
      </a>
    </div>
  </div>
  <div class="results">
    <ol id="post-out">
      <li v-for="(post,index) in result" :key="index" @click="$router.push({name: post.item.id}).catch(() => {}), routeChange()">{{ post.item.title }}</li>
    </ol>
  </div>
</div>
</template>

<script>
import VueFuse from 'vue-fuse'
import data from '@/statics/data/blogs.json';
import SearchFocus from './SearchFocus';

export default {
    name: 'Header',
    components: {
    VueFuse,
    SearchFocus
  },
  data () {
    return {
      posts: [],
      result: [],
    }
  }, 
  created () {
      Object.keys(data).forEach(element => {
        data[element].forEach(post => {
          this.posts.push(post);
        })
      })
  },
  methods: {
    routeChange () {
      let resultBox = document.getElementsByClassName('results')[0];
      let searchBox = document.getElementsByClassName('search')[0];      
      resultBox.style.display = "none";
      searchBox.value = "";
      this.res = [];
    },
    focusSearch(e) {
      if(document.getElementsByClassName("search")[0].style.display != "none") {
        if (e.key === '/') {
          document.getElementsByClassName("search")[0].focus();
        }
        else if (e.key === "Escape") {
          document.activeElement.blur();
        }
      }
    },
    handleResults (res) {
      let resultBox = document.getElementsByClassName('results')[0];
      let searchBox = document.getElementsByClassName('search')[0];

      if(searchBox.value != "") {
        if(res.length == 0) {
          resultBox.style.display = "none";
        }
        else {          
          resultBox.style.display = "block";
          this.result = res;
        }
      }
      else {
        resultBox.style.display = "none";
      }
    },
    checkSize () {
      let windowWidth = document.documentElement.clientWidth;
      let resultBox = document.getElementsByClassName('results')[0];

      if(windowWidth <= 768) {
        resultBox.style.display = "none";
      } 
    }
  },
  mounted () {
    this.$nextTick(function() {
      window.addEventListener('resize', this.checkSize);
      this.checkSize();
    })
  },
  computed: {
    options () {
    return {
      includeScore: true,
      shouldSort: true,
      keys: [
        'title',
        'description',
        'tags'
      ],
    }
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables.scss";

#heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem 0 1.5rem;

  h1 {
      font-family: consolas, monospace;
      font-size: 20px;
      letter-spacing: 1px;
      color: #0097a7;
      cursor: pointer;
  }

  a {
      margin-right: 1.5rem;
  }

  #heading-right {
    display: flex;
    justify-content: space-between;
    
    .search {
      margin-right: 2rem;
      padding: 0.4rem 1rem;
      border: 1.5px solid rgb(133,133,133);
      background: none;
      border-radius: 15px;
      color: #fff ;
      font-family: 'Fira Sans';
      font-weight: bold;
      width: 250px;
      display: none;

      &::-webkit-search-cancel-button, &::-ms-clear {
        display: none;
      }

      &:focus {
        outline: none !important;
        border: 1.5px solid #fff;
      }
    }
  }

  .svg-icon {
    width: 1.5em;
    height: 1.5em;
    
    position: relative;
    top: 3px;
  }

  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #ffffff;
  }

  .svg-icon circle {
    stroke: #ffffff;
    stroke-width: 1;
  }
}

.results {
  background: #fff;
  padding: 0.2rem 1rem 0.8rem 1rem;
  width: 250px;
  margin-top: 0.3rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: none;

  &::-webkit-scrollbar {
    width: 8px;               
  }

  &::-webkit-scrollbar-track {
    background: $--text-primary;        
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5%; 
    background-color: $--text-secondary;
    border: 1px solid $--text-secondary;  
  }

    
  #post-out {
      list-style:none;

      li {
        color: #0097a7;
        padding: 1rem 0;
        font-weight: bold;
        border-bottom: 1px solid #0097a7;
        font-family: "Fira Sans";
        cursor: pointer;
      }
    }
}

@media only screen and (min-width: 768px) {
  .results {
    left: 342px;
    display: none;
    max-height: 250px;
    overflow-y: scroll;
  }
  #heading {
    #heading-right{
      .search {
        display: block;
      }
    }
  }
}
</style>