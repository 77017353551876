<template>
<div id="blog-list">
  <div class="sections">
      <div v-for="(section, index) in Object.keys(blogs)" :key="index" class="group">
        <!-- <h2 class="center">{{section}}</h2> -->
        <div class="blog-post" v-for="entry in blogs[section]" :key="entry.id">
          <!-- <div class="sec">{{ section[0].toUpperCase() + section.substring(1,) }}</div> -->
          <div class="title">
            <h2 @click="$router.push({name: entry.id})">
              {{entry.title}}
            </h2>
            <span class="date">{{entry.date}}  <span class="divider">/</span>  {{ section[0].toUpperCase() + section.substring(1,) }}</span>
            <p>{{entry.description}}</p>
          </div>
          <h4>
              <span @click="$router.push({name: entry.id})">Read More</span>
          </h4>
        </div>
      </div>
    </div>

    <paginate
    :page-count= pagecount
    :page-range="3"
    :click-handler="paginate"
    :container-class="'pagination'"
    :page-class="'page-item'"
    :page-link-class="'item'"
    :prev-class="'prev-item'"
    :next-class="'next-item'"
    :prev-text="'Prev'"
    :next-text="'Next'"
    :next-link-class="'next-a'"
    :prev-link-class="'prev-a'">
  </paginate>
</div>
</template>

<script>
import BLOGENTRIES from '@/statics/data/blogs.json'
  
export default {
    name: 'BlogList',
    data () {
      return {
        blogs: {}
      }
    },
    created() {
        let testObj = {};
        let count = 1;
        Object.keys(BLOGENTRIES).forEach((element) => {
          if(count + BLOGENTRIES[element].length <= 3)
            count+=BLOGENTRIES[element].length;
          else {
            if(count <= 3) {
              testObj[element] = [];
              BLOGENTRIES[element].forEach((item) => {
                if(count <= 3)
                  testObj[element].push(item); 
                // console.log(testObj[element]);
                count++;
              });
            }
          }  
        })
        // console.log(testObj, count);
        this.blogs = testObj;
    },
    computed: {
      entries() {
        return BLOGENTRIES
      },
      pagecount() {
        let count = 0;
        Object.keys(BLOGENTRIES).forEach((element) => {
          count += BLOGENTRIES[element].length;
        })
        if(count % 3 == 0) {
          return count/3;
        }
        return Math.ceil(count/3);
      }
    },
    methods: {
      paginate(start) {
        let end = (start*3) + 1;
        // console.log(start);
        start = end - 3;
        let testObj = {};
        let count = 1;
        Object.keys(BLOGENTRIES).forEach((element) => {
          if(count + BLOGENTRIES[element].length < start)
            count+=BLOGENTRIES[element].length;
          else {
            if(count < end) {
              testObj[element] = [];
              BLOGENTRIES[element].forEach((item) => {
                if(count >= start && count < end)
                  testObj[element].push(item); 
                // console.log(testObj[element]);
                count++;
              });
            }
          }  
        })
        // console.log(testObj);
        this.blogs = testObj;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
</script>

<style lang="scss">
    @import "../scss/variables.scss";

    #blog-list {
        padding: 2.5rem 1.5rem 0 1.5rem;
        font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
        font-size: $--blog-post-font-size;
        margin-bottom: 3rem;
    
        .sections {
            min-height: 663px;
            .sec {
              font-size: 16px;
              font-weight: bold;
              font-family: consolas;
              color: $--text-secondary;
            }
            .blog-post {
                margin-top: 2.5rem;
                border-bottom: 1px solid $--border-primary;

                h4 {
                  color: #00acc1;
                  margin-bottom: 2rem;

                  span {
                    font-size: 16px;
                  }
                  span:hover {
                    color: darken(#00acc1,10%);
                    cursor: pointer;
                  }
                }
                .title {
                    h2 {
                        font-size: 1.875rem;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.3s all ease;
                    }
                    h2:hover {
                        color: #00acc1;
                    }
                    .date {
                        color: $--text-secondary;
                        font-size: 17px;
                        display: block;
                        margin-top: 0.2rem;
                    }
                    .divider {
                      font-weight: bold;
                      padding: 0 0.5rem;
                    }
                    p {
                        font-size: 1.125rem;
                        font-family: "Fira Sans";
                        line-height: 1.5;
                        margin: 1rem 0 0 0;
                        padding-bottom: 1rem;
                    }
                }
            }
        }

      .pagination {
        display: flex;
        list-style: none;
        justify-content: center;
        margin: 3.5rem 0 1.5rem 0;
        align-items: center;

        .page-item > .item{
          color: #00acc1;
          padding: 0.1rem 0.8rem;
          // border: 1px solid #00acc1;
          border: 1px solid $--background-primary;
          cursor: pointer;
          transition: all 0.5s;

          &:hover {
            background-color: #00acc1;
            color: #ffffff;
          }
        }

        .prev-item, .next-item {
          font-size: 1.2rem;
          padding: 0.1rem 0.8rem;
          color:#00acc1;
          font-weight: bold;
          transition: all 0.5s;
          outline: 0 !important;
          border: none;

          &:hover {
            color: #ffffff;
          }
        }

        .next-a:focus,.prev-a:focus, .item:focus {
          outline: none !important;
        }

        .active > .item{
          background-color: #00acc1;
          color: #ffffff;
          border: 1px solid $--background-primary;
        }
      }
    }

</style>