<template>
  <div id="app">
    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'Blog.',
      htmlAttrs: {
      lang: 'en',
      amp: true
    },
    base: { 
      target: '_self', 
      href: '/' 
    },
    meta: [
      { charset: 'utf-8' },
      { 
        name: 'viewport', 
        content: 'width=device-width, initial-scale=1' 
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'The blog site of Yudishthir Lokhande. Read about an array of topics like Food, Tech, Travel, Dev and many more. I just write what I feel.'
      },
      {
        name: 'keywords',
        content: "Yudishthir Lokhande, Yudishthir, Lokhande, Blog, MIT, Manipal, Yudi Lokhande, Yudi Lokhande Blog, Yudishthir Lokhande Blog"
      }
    ]
    }
  }
</script>

<style lang="scss">
@import "./scss/variables.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $--text-primary;
  background-color: $--background-primary;
  border-top: 14px solid #00acc1;
}

body {
  &::-webkit-scrollbar {
      width: 10px;               
    }

    &::-webkit-scrollbar-track {
      background-color: $--border-primary;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $--text-secondary;
      border: 1px solid $--text-secondary;  
    }
}
</style>
