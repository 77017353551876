<template>
  <div class="home">
      <Header></Header>
      <BlogList></BlogList>
      <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import BlogList from '../components/BlogList.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    BlogList,
    Footer
  },
  metaInfo: {
      title: 'Blog.',
      htmlAttrs: {
      lang: 'en',
      amp: true
    },
    base: { 
      target: '_self', 
      href: '/' 
    },
    meta: [
      { title: 'Home'},
      { charset: 'utf-8' },
      { 
        name: 'viewport', 
        content: 'width=device-width, initial-scale=1' 
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'The blog site of Yudishthir Lokhande. Read about an array of topics like Food, Tech, Travel, Dev and many more. I just write what I feel.'
      },
      {
        name: 'keywords',
        content: "Yudishthir Lokhande, Yudishthir, Lokhande, Blog, MIT, Manipal, Yudi Lokhande, Yudi Lokhande Blog, Yudishthir Lokhande Blog"
      }
    ]
    }
}
</script>

<style lang="scss">
  .home {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

 @media only screen and (min-width: 768px) {
   #blog-list, #header {
     width: 800px;
     margin: auto;
   }

   #header {
     margin-bottom: 0;
   }
   #blog-list {
     margin-top: 0;
   }

   #footer {
      display: flex;
      justify-content: space-around;
      margin-top: 3rem;

      ul {
        margin: 0;
      }
   }
}
</style>