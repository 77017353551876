import Vue from 'vue'
import App from './App.vue'
import VueTypedJs from 'vue-typed-js'
import router from './router'
import Paginate from 'vuejs-paginate'
import VueMeta from 'vue-meta'

Vue.component('paginate', Paginate)
Vue.use(Paginate)
Vue.use(VueTypedJs)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
